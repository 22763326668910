export const branding = {
  brandName: 'Tera Fund', 
  earthRewards: 'Rewards',
  username: 'Username',
  lightLogo: require('./branding/tera-light.png'),
  darkLogo: require('./branding/tera-dark.png'),
  webPage: 'tera.fund',
  apiUrl: 'https://api.liquidtoken.net',
  equityWallet: 'Account',
  rewardsWallet: 'Rewards'
}