import React, { Component } from 'react';
import { connect } from 'react-redux';
import { balances, users, projects as request } from '../../../controllers/requests';
import { EarthActivityCard, EarthCard, EarthText, BalanceCard, EarthModal } from '../../../components';
import Checkout from "../../../components/Checkout/Checkout";
import Cake from '../../../components/Charts/Cake/Cake';
import './styles.scss';
import { FormInput, FormSelect } from 'shards-react';
import { toast } from 'react-toastify';

const options = [
  { name: 'Donate', image: require('../../../assets/images/Instructions.png') },
  { name: 'Prospectus', location: '/projects/prospectus', image: require('../../../assets/images/Prospectus.png') },
  { name: 'KYC', location: '/profile', image: require('../../../assets/images/KYC.png') },
  { name: 'Security', location: '/settings/security', image: require('../../../assets/images/Security.png') },
  { name: 'Wallet', location: '/wallet', image: require('../../../assets/images/Wallet.png') },
  { name: 'Invest', location: '/projects', image: require('../../../assets/images/Invest.png') },
  { name: 'Dividend', location: '#yield', image: require('../../../assets/images/Dividend.png') },
  { name: 'Voting', location: '#yield', image: require('../../../assets/images/Voting.png') }
];

class EarthDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equityTokens: [], rewardsTokens: [], activity: [], finalPrice: 0,
      showModal: false, projects: [], loadingProjects: false
    }
  }

  componentDidMount() {
    this.setState({ loadingEquity: true });
    users.getActivity().then(activity => this.setState({ activity }));
    request.getProjects().then(({ projects }) => this.setState({ projects, loadingProjects: false }));
    balances.getBalances().then(equityTokens => this.setState({ loadingEquity: false, equityTokens }))
  }

  render() {
    const { history } = this.props;
    const { equityTokens, loadingEquity, activity, showModal, finalPrice, projects } = this.state;
    return (
      <div className="EarthDashboard">
        <div className='row mb-4'>
          {
            options.map((option, index) => (
              <div key={index} className='col-6 col-md-3 mt-3'>
                <EarthCard
                  style={{ width: '100%', height: '100%' }}
                  hover grow className='text-center'
                  onClick={() =>
                    !option.location ?
                      this.setState({ showModal: true })
                      :
                      option.location &&
                        option.location.charAt(0) === '/' ?
                        history.push(option.location)
                        :
                        this.refs[option.location.substr(1)].scrollIntoView({ block: 'center', behavior: 'smooth' })
                  }
                >
                  <img className='mb-2' width={50} src={option.image} alt='img' />
                  <EarthText>{option.name}</EarthText>
                </EarthCard>
              </div>
            ))
          }
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-6 mb-4">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <EarthText fontStyle='bold'>Wallet Balance</EarthText>
              <div>
                <EarthText className='inline'><strong>Cash Balance: </strong></EarthText>
                <EarthText className='inline cian'>$50,000</EarthText>
              </div>
            </div>
            <div className='divider' />
            {
              equityTokens.map(token => <BalanceCard {...token} />)
            }
          </div>
          <div className="col-12 col-md-6 mb-4">
            <div ref='yield' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <EarthText fontStyle='bold'>Investment Portfolio</EarthText>
              <div>
                <EarthText className='inline'><strong>Yield: </strong></EarthText>
                <EarthText className='inline cian'>6%</EarthText>
              </div>
            </div>
            <div className='divider' />
            <Cake tokens={equityTokens} loading={loadingEquity} />
          </div>
        </div>

        <div className="row activity">
          <div className="col-xl-12 mb-2">
            <EarthText className='mb-3' size='xlarge' fontStyle='bold'>Activity</EarthText>
            <EarthActivityCard activities={activity} />
          </div>
        </div>
        <EarthModal
          onClose={() => this.setState({ showModal: false })}
          show={showModal}
          style={{ textAlign: 'center' }}
        >
          <label className='isLabel'>Amount to donate</label>
          <FormInput onChange={({ target: { value } }) => this.setState({ finalPrice: value })} />
          <label className='isLabel'>Project to donate</label>
          <FormSelect className='form-group'>
            {projects.map((project, index) => <option key={index}>{project.name}</option>)}
          </FormSelect>
          
          <Checkout
            onPurchase={() => (
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve({});
                }, 2000);
              }))}
            onPurchaseComplete={() => {
              this.setState({ showModal: false });
            }}
            finalPrice={finalPrice}
            label={`You are about to donate ${finalPrice} USD`}
          />
        </EarthModal>
      </div >
    )
  }
}

const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}

export default connect(mapStatetoProps)(EarthDashboard);